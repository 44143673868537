<template>
  <header>
    <!-- logo  -->
    <div class="logo">
      <img src="../assets/image/logo.png" alt="" />
    </div>
    <!-- 导航 -->
    <div class="header_nav">
      <div v-for="(item, index) in nav" :key="index">
        <div class="header_nav_item" :class="index == active ? 'header_cur' : ''"
          @click="handleClick(index, item.router, item.name)">
          <img :src="index == active ? item.hoverImg : item.urlImg" class="header_icon" />
          <span>{{ item.name }}</span>
        </div>
      </div>
    </div>
    <!-- 个人中心 / 退出 -->
    <div class="header_right" v-if="user != null">
      <div class="userInfo" @click="handleInfo">
        <img src="../assets/image/user.png" class="user_img" />
        <span>{{ user.phone }}({{ user.brand }})</span>
      </div>
      <div class="userss">
        
        <el-dropdown trigger="click">
          <span class="el-dropdown-link">
            <i class="el-icon-user userIcon"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>
              <a class="user_url" href="http://sku.saas.datamonitor.shop/#/skupricingmanagement" target="_blank">SKU定价管理</a>
            </el-dropdown-item>
            <el-dropdown-item>
              <a class="user_url" href="http://mall.saas.datamonitor.shop/storemanagement" target="_blank">店铺管理</a>
            </el-dropdown-item>
            <el-dropdown-item>
              <a class="user_url" href="http://crawler.saas.datamonitor.shop/dpcrawler" target="_blank">数据采集</a>
            </el-dropdown-item>
            
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <router-link class="header_out" to="/Login">
        <img src="../assets/image/tuichu.png" class="user_out" />
        <span>退出</span>
      </router-link>
    </div>
  </header>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      current: (state) => state.tab.currentMenu
    }),
    user() {
      console.log(localStorage.getItem("user"))
      if (JSON.parse(localStorage.getItem("user"))) {
        this.$store.commit("user", JSON.parse(localStorage.getItem("user")));
      } else {
        this.$store.commit("user", null);
      }
      return this.$store.state.tab.user;
    },
  },
  data() {
    return {
      active: sessionStorage.getItem("active") || 0,
      nav: [
        {
          name: "数据看板",
          router: "/",
          urlImg: require("../assets/image/shuju.png"),
          hoverImg: require("../assets/image/shuju_pre.png"),
        },
        // {
        //   name: "低价提醒",
        //   router: "/remind",
        //   urlImg: require("../assets/image/tixing.png"),
        //   hoverImg: require("../assets/image/tixing_pre.png"),
        // },
        {
          name: "价格体系",
          router: "/priceSystem",
          urlImg: require("../assets/image/tags.png"),
          hoverImg: require("../assets/image/tags_pre.png"),
        },
        // {
        //   name: "常见问题",
        //   router: "/issue",
        //   urlImg: require("../assets/image/wenti.png"),
        //   hoverImg: require("../assets/image/wenti_pre.png"),
        // },
        {
          name: "定时查询",
          router: "/task",
          urlImg: require("../assets/image/task.png"),
          hoverImg: require("../assets/image/task_pre.png"),
        },
        {
          name: "店铺管理",
          router: "/shops",
          urlImg: require("../assets/image/dp.png"),
          hoverImg: require("../assets/image/dp_pre.png"),
        },
        {
          name: "数据采集",
          router: "/gather",
          urlImg: require("../assets/image/cx.png"),
          hoverImg: require("../assets/image/cx_pre.png"),
        },
      ],
    };
  },
  methods: {
    // 跳转页面
    handleClick(index, router, name) {
      this.active = index;
      sessionStorage.setItem("active", index);
      this.$router.push({
        path: router,
      });
      this.$store.commit("removeTabs", { name, router });
    },
    // 个人中心
    handleInfo() {
      this.active = -1;
      sessionStorage.setItem("active", -1);
      this.$router.push({
        path: "/user",
      });
      this.$store.commit("removeTabs", { name: "个人信息", router: "/user" });
    },
  },
};
</script>

<style lang="scss" scoped>
header {
  height: 100%;
  color: #333333;
  display: flex;
  align-items: center;

  .logo {
    img {
      width: auto;
      height: 23px;
    }
  }

  .header_nav {
    margin-left: 50px;
    display: flex;
    flex: 1;

    .header_cur {
      background-color: #e6f7ff;
      color: #3978f7;
    }

    .header_nav_item {
      width: 160px;
      height: 60px;
      display: flex;
      align-items: center;
      font-size: 16px;
      justify-content: center;

      &:hover {
        background-color: #e6f7ff;
        cursor: pointer;
      }

      .header_icon {
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }
    }
  }
}

.header_right {
  display: flex;

  .userInfo {
    font-size: 16px;
    color: $text_color;
    display: flex;
    align-items: center;
    cursor: pointer;

    .user_img {
      width: 32px;
      height: 32px;
      border-radius: 50%;
    }

    span {
      padding-left: 8px;
    }
  }

  .userss {
    margin-left: 20px;
    display: flex;
    cursor: pointer;
    align-items: center;
    
    .userIcon{
      font-size: 23px;
    }
  }

  .header_out {
    cursor: pointer;
    font-size: 16px;
    color: #f42f2f;
    display: flex;
    align-items: center;
    margin-left: 44px;

    .user_out {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
  }
}
.user_url{
  color:#333
}
</style>

<style lang="scss">
.el-breadcrumb__inner a,
.el-breadcrumb__inner.is-link {
  color: #ffffff;
}

.el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
  color: red;
}</style>
