<template>
  <el-container style="height: 100%">
    <el-header>
      <common-header></common-header>
    </el-header>
    <el-main>
      <router-view></router-view>
    </el-main>
  </el-container>
</template>

<script>
import CommonHeader from "../components/CommonHeader";

export default {
  components: {
    CommonHeader
  },
};
</script>

<style lang="scss" scoped>
.el-header {
  background-color: #ffffff;
  color: #333333;
  box-shadow: 0px 1px 4px 0px rgba(0, 21, 41, 0.12);
  position: relative;
  z-index: 10;
}
.footer {
  width: 100%;
  height: 60px;
  padding: 40px 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .footer_top {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.45);
    line-height: 22px;
  }
  .footer-btn {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.45);
    line-height: 20px;
  }
}
</style>
